class heroWithSlideshow extends HTMLElement {
  constructor() {
    super();
    this.heroWithSlideshow = this;

    this.allSlides = this.heroWithSlideshow.querySelectorAll('.slider-item');

    window.addEventListener("load", () => {
      this.initObserver();
      window.addEventListener("shopify:section:load", () => {
        this.initObserver();
      });
    });
  }

  initObserver() {
    var observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        this.activeSlide = entry.target;
        if (entry.isIntersecting) {
          clearTimeout(this.slideTimeout);
          this.setSlideDurationToVideoDuration();
        }
      });
    },
    { threshold: 0.2 });
    this.allSlides.forEach((slide) => observer.observe(slide));
  }

  setSlideDurationToVideoDuration() {
    if (this.activeSlide.dataset.slideType === 'video') {
      this.player = new Vimeo.Player(this.activeSlide.querySelector('iframe'));
      this.player.setCurrentTime(0);
      this.player.getDuration().then(function (duration) {
        if (this.heroWithSlideshow.classList.contains('slider-nav-dynamic-autoplay')) {
          var timeout = duration ? duration.toFixed(3).replace('.', '') : 5000;
          this.slideTimeout = setTimeout(function () {
            if (this.allSlides.length > 1) {
              if (this.heroWithSlideshow.classList.contains("slider-item-last-visible")) {
                swiffyslider.slideTo(this.heroWithSlideshow, 0);
              } else {
                swiffyslider.slide(this.heroWithSlideshow, true);
              }
              this.player.pause();
            }
          }.bind(this), timeout);
        }
      }.bind(this));
    } else {
      var timeout = this.heroWithSlideshow.getAttribute('data-slide-interval') ? this.heroWithSlideshow.getAttribute('data-slide-interval') : 5000;
      this.slideTimeout = setTimeout(function(){
        if (this.allSlides.length > 1) {
          if (this.heroWithSlideshow.classList.contains("slider-item-last-visible")) {
            swiffyslider.slideTo(this.heroWithSlideshow, 0);
          } else {
            swiffyslider.slide(this.heroWithSlideshow, true);
          }
        }
      }.bind(this), timeout);
    }
  }

}
customElements.define('hero-with-slideshow', heroWithSlideshow);
